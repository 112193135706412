import { DrupalNode } from "next-drupal";
import Link from "next/link";
import classNames from "classnames";
import { absoluteURL, formatDate } from "lib/utils";
import Image from "next/image";

import {
  CalendarIcon,
  MapPinIcon,
} from "@heroicons/react/20/solid";

interface BlockAgendaNewProps {
  node: DrupalNode;
}

export function BlockAgendaNew({ node, ...props }: BlockAgendaNewProps) {
  return (
<article {...props} className="pl-5">
  <div className="lg:flex lg:gap-x-16 lg:h-full">
    {/* Colonne gauche */}
    <div className="lg:w-5/12 lg:flex-shrink-0 lg:h-full">
      <div className="isolate  text-center gap-px rounded-lg bg-gray-100 text-sm shadow ring-1 ring-gray-200 lg:h-full">
        {node
          .filter((agenda) => agenda.sticky === true)
          .slice(0, 1)
          .map((agenda) => (
            <Link
              href={agenda.path.alias}
              key={agenda.id}
              className="sm:flex lg:block hover:bg-white"
              passHref
            >
              <div className="sm:flex-shrink-0">
                <Image
                  src={absoluteURL(
                    agenda.field_media_image.field_media_image.uri.url
                  )}
                  alt={
                    agenda.field_media_image.field_media_image.resourceIdObjMeta.alt
                  }
                  width={500}
                  height={300}
                  layout="responsive"
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  objectFit="cover"
                  priority={true}
                />
              </div>
              <div className=" sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                <div className="flex-auto p-3">
                  <h3 className="pr-10 text-left text-xl font-bold text-gray-900 xl:pr-0">
                    {agenda.title}
                  </h3>
                  <dl className="mt-2 flex flex-col mx-auto text-gray-600 xl:flex-row">
                    <div className="flex items-start space-x-3">
                      <dt className="mt-0.5">
                        <span className="sr-only">Date</span>
                        <CalendarIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </dt>
                      <dd>
                        <time dateTime={agenda.field_date_de_l_agenda.value}>
                          {formatDate(agenda.field_date_de_l_agenda.value)} at{" "}
                          {formatDate(agenda.field_date_de_l_agenda.end_value)}
                        </time>
                      </dd>
                    </div>
                    <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                      <dt className="mt-0.5">
                        <span className="sr-only">Localisation</span>
                        <MapPinIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </dt>
                      <dd>
                        {agenda.location}
                        {agenda.field_paragraphs.map((localisation) => (
                          <span key={localisation.id}>
                            {localisation.field_lieu}
                          </span>
                        ))}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>

    {/* Colonne droite */}
    <div className="lg:w-7/12 lg:h-full overflow-y-auto max-h-[600px] relative">
      <ol className="mt-4 divide-y divide-gray-100 leading-6">
        {node
          .filter((agenda) => agenda.sticky === false)
          .map((agenda) => (
            <Link
              href={agenda.path.alias}
              key={agenda.id}
              className="relative flex space-x-6 py-6 xl:static hover:bg-gray-100"
              passHref
            >
              {agenda.field_media_image?.field_media_image?.uri?.url && (
                <Image
                  src={absoluteURL(
                    agenda.field_media_image.field_media_image.uri.url
                  )}
                  alt={
                    agenda.field_media_image.field_media_image.resourceIdObjMeta
                      .alt || "Image"
                  }
                  title={
                    agenda.field_media_image.field_media_image.resourceIdObjMeta
                      .alt || "Image title"
                  }
                  width={253}
                  height={390}
                  className="h-14 w-14 flex-none"
                  style={{ objectFit: "contain" }}
                  loading="lazy"
                />
              )}

              <div className="flex-auto">
                <h3 className="pr-10 font-bold text-gray-900 xl:pr-0">
                  {agenda.title}
                </h3>
                <dl className="mt-2 flex flex-col text-gray-600 xl:flex-row">
                  <div className="flex items-start space-x-3">
                    <dt className="mt-0.5">
                      <span className="sr-only">Date</span>
                      <CalendarIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                      <time dateTime={agenda.field_date_de_l_agenda.value}>
                        {formatDate(agenda.field_date_de_l_agenda.value)} at{" "}
                        {formatDate(agenda.field_date_de_l_agenda.end_value)}
                      </time>
                    </dd>
                  </div>
                  <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                    <dt className="mt-0.5">
                      <span className="sr-only">Localisation</span>
                      <MapPinIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                      {agenda.location}
                      {agenda.field_paragraphs.map((localisation) => (
                        <span key={localisation.id}>
                          {localisation.field_lieu}
                        </span>
                      ))}
                    </dd>
                  </div>
                </dl>
              </div>
            </Link>
          ))}
      </ol>
    </div>
  </div>
</article>


  );
}
